import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/StateContext';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { RoutedHeader, LinkedTree, Entity } from '../hanu-ui/hanu-ui';
import icons from '../icons';
import { Layout, Drawer, Tag, Button } from 'antd';
import YAML from 'yaml';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';


const CommitDrawer = ({ oktokit, params, commit, environments, onClose }) => {
  const deployTo = async (environment) => {
    const { data } = await oktokit.request(`POST /repos/:owner/:repo/deployments`, {
      ...params,
      ref: commit.sha,
      environment,
      payload: {
          environment,
      }
    });
    console.log(data);
  }

  return (
    <Drawer
      title={commit.commit.message}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={true}
    >
      {commit.sha}
      {environments.map((environment) => (
        <Button onClick={() => deployTo(environment.environment)}
          type="primary"
          key={environment.environment}>Deploy to {environment.environment}</Button>
      ))}
    </Drawer>
  )
}

const CommitExtra = ({ commit }) =>{
  const { state } = useStateContext();
  const deployments = Object.values(get(state, 'github.deployments', {})).filter((deployment) => deployment.sha === commit.sha);
  return (
    <span>
      {deployments.map((deployment) => (
        <Tag key={deployment.id} icon={<CheckCircleOutlined />} color="success">{deployment.environment}</Tag>
      ))}
      <Tag icon={<UserOutlined />}>{commit.author.login}</Tag>
    </span>
  );
}

const Branch = () => {
  const { state, dispatch } = useStateContext();
  const params = useParams();
  const [treeData, setTreeData] = useState([]);
  const history = useHistory();
  const [environments, setEnvironments] = useState(); 

  const fullRepo = `${params.owner}/${params.repo}`;

  useEffect(() => {
    if (!state.oktokit) return;
    (async () => {
      try {
        const { data } = await state.oktokit.request(`GET /repos/:owner/:repo/deployments?per_page=100`, pick(params, [
          'owner',
          'repo',
        ]));
        dispatch({
          type: 'update',
          path: 'github.deployments',
          payload: Object.values(data).reduce((reduced, row) => {
            return Object.assign(reduced, {
              [row.id]: row,
            });
          }, {}),
        });
      } catch(err) {
        console.error(err);
      }
    })();

  }, [state.oktokit, params, dispatch]);

  useEffect(() => {
    if (!state.oktokit) return;
    (async () => {
      try {
        const { data } = await state.oktokit.request(`GET /repos/:owner/:repo/deployments?sha=${params.sha}&per_page=100`, pick(params, [
          'owner',
          'repo',
        ]));
        dispatch({
          type: 'update',
          path: 'github.deployments',
          payload: Object.values(data).reduce((reduced, row) => {
            return Object.assign(reduced, {
              [row.id]: row,
            });
          }, {}),
        });
      } catch(err) {
        console.error(err);
      }
    })();

  }, [state.oktokit, dispatch, params]);

  useEffect(() => {
    if (!state.oktokit) return;
    (async () => {
      try {
        const { data } = await state.oktokit.request(`GET /repos/:owner/:repo/contents/.github/deploy.yml?ref=${params.branch}`, {
          ...params,
          headers: {
            accept: 'application/vnd.github.VERSION.raw' 
          }
        });
        const config = YAML.parse(data);
        setEnvironments(Object.values(config));

      } catch(err) {
        console.error(err);
      }
    })();

    (async () => {
      const { data } = await state.oktokit.request(`GET /repos/:owner/:repo/commits?sha=${params.branch}&per_page=100`, pick(params, [
        'owner',
        'repo',
      ]));
      setTreeData(data.map((item) => ({
        item: Object.assign(item, {
          type: 'Commit',
        }),
        key: item.sha,
        title: (
          <span>
            {item.commit.message}
          </span>
        ),
        icon: icons['Commit'],
      })));
    })();

  }, [state.oktokit, params]);

  const selectedKeys = [
    params.sha,
  ];
  const node = treeData.find((node) => node.item.sha === params.sha);

  return (
    <div>
      <RoutedHeader
        title={(
          <Entity icon={icons['Branch']} caption={params.branch} />
        )}
        subTitle="Branch"
        breadcrumbs={[
          {
            path: '/github',
            breadcrumbName: 'Repositories',
          },
          {
            path: `/github/${fullRepo}`,
            breadcrumbName: (
              <Entity icon={icons['Repository']} caption={fullRepo} />
            ),
          },
        ]}
      />
      <LinkedTree
        extraRender={(node) => (
          <CommitExtra commit={node.item} />
        )}
        selectedKeys={selectedKeys}
        onSelect={(node) => history.push(`/github/${fullRepo}/${params.branch}/${node.item.sha}`)}
        treeData={treeData}
      />
      {node && (
        <CommitDrawer
          params={params}
          oktokit={state.oktokit}
          environments={environments}
          commit={node.item}
          onClose={() => history.push(`/github/${fullRepo}/${params.branch}`)} />
      )}
    </div>
  );
}

export default Branch;