import React, { useState, useEffect } from 'react';

import 'antd/dist/antd.css';
import './App.scss';

import FirebaseContext from './contexts/FirebaseContext';
import StateContext, { useStateContext } from './contexts/StateContext';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './components/Login';
import routes from './routes';
import { Layout } from 'antd';
import { LayoutFlex } from './hanu-ui/hanu-ui';

function App() {

  return (
    <LayoutFlex>
      <Layout.Header>
        <div></div>
        <Login /> 
      </Layout.Header>
      <Layout.Content>
        <Switch>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </Layout.Content>
    </LayoutFlex>
  );
}

const AppWrapper = () => {
  return (
    <StateContext>
      <FirebaseContext
        config={{
          apiKey: "AIzaSyAvC9VlOAmrkZ0fVuEsR2qDXUAr-7GFNZM",
          authDomain: "smile-devops.firebaseapp.com",
          databaseURL: "https://smile-devops.firebaseio.com",
          projectId: "smile-devops",
          storageBucket: "smile-devops.appspot.com",
          messagingSenderId: "46104035125",
          appId: "1:46104035125:web:7fe1c951ed0fc148d0167c",
          measurementId: "G-LZNNJC8Y6D"
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </FirebaseContext>
    </StateContext>
  )
}

export default AppWrapper;