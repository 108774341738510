import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/StateContext';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { RoutedHeader, LinkedTree, Entity } from '../hanu-ui/hanu-ui';
import icons from '../icons';
import { Layout } from 'antd';

const Repository = () => {
  const { state } = useStateContext();
  const params = useParams();
  const [treeData, setTreeData] = useState([]);
  const history = useHistory();

  const fullRepo = `${params.owner}/${params.repo}`;

  useEffect(() => {
    if (!state.oktokit) return;
    (async () => {
      const { data } = await state.oktokit.request(`GET /repos/${fullRepo}/branches?per_page=100`);
      setTreeData(data.map((item) => ({
        item: Object.assign(item, {
          type: 'Branch',
        }),
        key: item.name,
        title: item.name,
        selectable: false,
        icon: icons['Branch'],
        to: `/github/${params.owner}/${params.repo}/${item.name}`,
      })));
    })();

  }, [state.oktokit, fullRepo]);

  // const selectedKeys = [
  //   `${params.owner}/${params.repo}/${params.sha}`,
  // ];
  // console.log(params);
  return (
    <div>
      <RoutedHeader
        title={(
          <Entity icon={icons['Repository']} caption={fullRepo} />
        )}
        subTitle="Repository"
        breadcrumbs={[
          {
            path: '/github',
            breadcrumbName: 'Repositories',
          },
        ]}
      />
      <LinkedTree
        // selectedKeys={selectedKeys}
        // showSearch={true}
        onSelect={(node) => history.push(`/github/${params.owner}/${params.repo}/${node.item.sha}`)}
        // loadChildren={async ({ key, node }) => {
        //   if (node.item.type === 'Branch') {
        //     const { data } = await state.oktokit.request(`GET /repos/${fullRepo}/commits?sha=${node.item.name}`)
        //     return data.map((item) => ({
        //       item: Object.assign(item, {
        //         type: 'Commit',
        //       }),
        //       isLeaf: true,
        //       title: item.commit.message,
        //       key: `${key}/${item.sha}`,
        //       icon: icons['Commit'],
        //     }));
        //   }
        //   return undefined;
        // }}
        treeData={treeData}
      />
    </div>
  );
}

export default Repository;