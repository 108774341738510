import React, { useEffect, useState } from 'react';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import { Button, Dropdown, Menu } from 'antd';
import { useStateContext } from '../contexts/StateContext';
import { useFirebaseDatabase } from '../hooks/firebase';
// import ClientPill from './ClientPill';

import pick from 'lodash/pick';
import { DownOutlined, PoweroffOutlined } from '@ant-design/icons';

const Login = () => {
  const { app, user, sdk } = useFirebaseContext();
  const { dispatch } = useStateContext();
  const [providers, setProviders] = useState({});
  const { database, update } = useFirebaseDatabase();

  useEffect(() => {
    if (!sdk) return;

    const google = new sdk.auth.GoogleAuthProvider();
    // github.addScope('repo');
    // github.addScope('user');

    setProviders({
      google,
    });
  }, [sdk]);

  const socialLogin = async (provider) => {
    try {
      const { user, additionalUserInfo } = await app.auth().signInWithPopup(provider);
      update(`/users/${user.uid}`, {
        ...pick(user, [
          'uid',
          'displayName',
          'photoURL',
          'email',
          'emailVerified',
          'phoneNumber',
          'isAnonymous',
        ]),
        ...additionalUserInfo,
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (user === undefined) return null;
  if (user) return (
    <div>
      <Dropdown trigger={['click']} overlay={(
        <Menu>
          <Menu.Item
            key="1"
            icon={<PoweroffOutlined />} onClick={() => app.auth().signOut()}
          >
          Sign Out
        </Menu.Item>
      </Menu>
      )}>
        <span
        >
          {user.displayName} <DownOutlined />
        </span>
      </Dropdown>
    </div>
  );
  
  return (
    <div>
      {providers.google && (
      <Button
        type="danger"
        htmlType="button"
        className="login-form-button"
        style={{ marginRight: 10 }}
        onClick={() => socialLogin(providers.google)}
      >
        Login with Google
      </Button>
    )}
    </div>
  );
}

export default Login;
