import { useEffect, useState } from "react";

const { useFirebaseContext } = require("../contexts/FirebaseContext");

export const useFirebaseDatabase = () => {
  const { database } = useFirebaseContext();
  const [handler, setHandler] = useState({
    update: () => {},
  });

  useEffect(() => {
    setHandler({
      database,
      update: async (path, value) => {
        await database.ref(path).update(value);
        return;
      },
      remove: async (path) => {
        await database.ref(path).remove();
        return;
      }
    })
  }, [database]);

  return handler;
}




// export const useDatabaseRead = (path) => {
//   const { db, app } = useFirebaseContext();
//   const [value, setValue] = useState();

//   useEffect(() => {
//     if (!db) return;
//     const callback = (snapshot) => {
//       setValue(snapshot.val());
//     };
//     sdk.database().ref(path).on('value', callback);
//     return () => {
//       sdk.database().ref(path).off(callback);
//     }
//   }, [db, path])

//   return value;
// }