import React, { useEffect, useState } from 'react';
import { useFirebaseContext } from '../../contexts/FirebaseContext';
import { Octokit } from '@octokit/rest';
import { useFirebaseDatabase } from '../../hooks/firebase';
import { useStateContext } from '../../contexts/StateContext';
import { Input, Button } from 'antd';
import { InputSubmit } from '../../hanu-ui/hanu-ui';


const Wrapper = ({ Component }) => {
  const { user } = useFirebaseContext();
  const { database, update, remove } = useFirebaseDatabase();
  const { state, dispatch } = useStateContext();

  const [token, setToken] = useState(undefined)

  useEffect(() => {
    if (!user || !database) return;
    const callback = (snapshot) => {
      const auth = snapshot.val();
      setToken(auth || '');
      dispatch({
        type: 'set',
        path: 'oktokit',
        payload: auth ? new Octokit({
          auth,
        }) : null,
      });
    };
    database.ref(`/users/${user.uid}/credentials/github/accessToken`).on('value', callback);
    return () => {
      database.ref(`/users/${user.uid}/credentials/github/accessToken`).off('value', callback);
    }
  }, [user, database, dispatch]);

  const setGithubToken = ( accessToken) => {
    if (token === undefined) return;
    update(`/users/${user.uid}/credentials/github`, {
      accessToken,
    });
  }

  const removeGithubToken = () => {
    remove(`/users/${user.uid}/credentials/github`);
  }

  if (!user) return null;
  if (state.oktokit === undefined) return null;
  return (
    <div>
      {token && (
        <div
          className="github-bar"
        >Github
          <Button
            size="small"
            type="danger" onClick={removeGithubToken}>Remove Token</Button>
        </div>
      )}
      {state.oktokit ? (
        <Component />
      ) : (
      <div>
        You need to set a Github <a href="https://docs.github.com/en/free-pro-team@latest/github/authenticating-to-github/creating-a-personal-access-token"> Personal Access Token</a> in order to access repositories:
        <InputSubmit placeholder="Peronal Access Token" value={token} onChange={setGithubToken} />
      </div>

      )}      
    </div>
  )
}

// 15a4f735ad87d86abed923b29a617034a1da38e7
const WithGithub = (Component) => ({ ...props }) => {
  return (
    <Wrapper Component={Component} {...props} />
  );   
}

export default WithGithub;
