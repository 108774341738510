import React, { createContext, useState, useEffect, useContext } from 'react';

import * as sdk from 'firebase';

export const context = createContext(null);

export const FirebaseContext = ({ children, config = {} }) => {
  const [app, setApp] = useState();
  const [user, setUser] = useState();
  const [database, setDatabase] = useState();
  useEffect(() => {
    if (!app) return;
    app.auth().onAuthStateChanged(setUser);
  }, [app]);

  useEffect(() => {
    const app = sdk.initializeApp(config);
    setDatabase(sdk.database());
    setApp(app);
  }, [config]);

  return (
    <context.Provider value={{
      user,
      sdk,
      app,
      database,
    }}>
      {children}
    </context.Provider>
  );
};

export const useFirebaseContext = () => useContext(context);

export default FirebaseContext;
