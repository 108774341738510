import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Input, Button, Layout, PageHeader, Tree, TreeSelect } from 'antd';
import './hanu-ui.scss';
import { matchPath, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { reduce } from 'lodash';

export const PlainNavLink = ({ ...props }) => (
  <NavLink {...props} className="hn-plain-nav-link" />
)

export const Entity = ({ caption, icon }) => {
  return (
    <span className="hn-entity">
      {icon}
      {caption}
    </span>
  )
}


export const RoutedHeader = ({ title, breadcrumbs = [], ...props}) => {

  const routeMatch = useRouteMatch();

  return (
    <PageHeader
      breadcrumb={{
        itemRender: (route, params, routes, paths) => {
          return matchPath(route.path, routeMatch) ? (
            <span>{route.breadcrumbName}</span>
          ) : (
            <NavLink to={route.path}>{route.breadcrumbName}</NavLink>
          );
        },    
        routes: [
          ...breadcrumbs,
          {
            path: routeMatch.url,
            breadcrumbName: title,
          },
        ]
      }}
      title={title}
      {...props}
    />
  )
}



export const LayoutFlex = ({...props }) => {
  return (
    <Layout
      className="hn-layout-flex"
      {...props}
    />
  )
}

export const InputSubmit = ({ value: rawValue, onChange, ...props}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(rawValue || '');
  }, [rawValue]);

  const onSubmit = (ev) => {
    ev.preventDefault();
    onChange(value);
  }

  return (
    <form onSubmit={onSubmit} className="hn-input-submit">
      <Input
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        {...props}
      />
      <Button type="primary" htmlType="submit" disabled={value.length === 0}>Send</Button>
    </form>
  )
}

export const LinkedTree = ({ mode = "tree", treeData: rawTreeData, onSelect, selectedKeys = [],...props }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [textFilter, setTextFilter] = useState('');

  // const recursiveFilter = useCallback((nodes, filter) => {
  //   return [
  //     ...nodes.filter(filter).map((node) => ({
  //       ...node,
  //       children: node.children ? recursiveFilter(node.children, filter) : node.children,
  //     }))
  //   ]
  // }, []);

  const findNode = (list, key) => {
    if (!list) return undefined;
    return list.reduce((reduced, node) => {
      return reduced || ((node.key === key) ? node : findNode(node.children, key));
    }, undefined);
  }

  useEffect(() => {
    if (!rawTreeData) return;
    // const filtered = recursiveFilter(rawTreeData, (node) => {
    //   if (node.title.indexOf(textFilter) < 0) return false;
    //   return true;
    // });
    setTreeData(rawTreeData);

  }, [rawTreeData])

  if (props.loadChildren) Object.assign(props, {
    loadData: async ({ key }) => {
      const node = treeData.find((node) => node.key === key);
      if (!node) return;
      if (node.children || node.fetching) return node.fetching;
      node.fetching = props.loadChildren({ key, node })
      node.children = await node.fetching;
      node.fetching = undefined;
      setTreeData([
        ...treeData,
      ]);
    }
  })
  const Component = mode === "select" ? TreeSelect : Tree;

  return (
    <Fragment>
      {/* <Input
        prefix={(
          <SearchOutlined />
        )}
        type="search"
        value={textFilter} onChange={(ev) => setTextFilter(ev.target.value)} /> */}
      <Component
        className="hn-tree"
        {...props}
        onChange={(key) => {
          const node = findNode(treeData, key);
          onSelect && onSelect(node);
        }}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        onExpand={setExpandedKeys}
        onSelect={(selected, { node }) => {
          if (!node) return;
          onSelect && onSelect(node);
          setExpandedKeys(expandedKeys.includes(selected[0]) ? expandedKeys : [
            ...expandedKeys,
            selected[0],
          ]);
        }}
        showIcon
        titleRender={(node) => {
          const caption = node.to ? (
            <PlainNavLink to={node.to}>
              {node.title}
            </PlainNavLink>
          ) : (
            node.title
          );
          
          return (
            <Fragment>
              {caption}
              {props.extraRender && (
                <div className="hn-node-extra">
                  {props.extraRender(node)}
                </div>
              )}
            </Fragment>
          );
        }}
        treeData={treeData}
      />
    </Fragment>
  )
}