/* eslint-disable no-case-declarations */
import React, { createContext, useReducer, useContext } from 'react';

const initialState = {}

export const context = createContext(null);

const immute = (root, merge = true, path, payload) => {
  const steps = path.split('.');

  return steps.reduce(({ parent, root }, step, index) => {
    const lastStep = index === steps.length - 1;

    if (lastStep) {
      parent[step] = merge ? {
        ...(merge ? (parent[step] || {}) : undefined),
        ...payload,
      } : payload;
      
    } else {
      parent[step] = {
        ...(parent[step] || {}),
      };
    }
    if (parent[step] === undefined) delete parent[step];
    
    return lastStep ? (root || parent) : {
      parent: parent[step],
      root: root || parent,
    }
  }, {
    parent: {
      ...root
    },
  });
}

export const StateContext = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'update':
        return immute(state, true, action.path, action.payload);
      case 'set':
        return immute(state, false, action.path, action.payload);
      case 'clear':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  }, initialState);
  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>;
}

export const useStateContext = () => useContext(context);

export default StateContext;

