import React from 'react';
import {
  UserOutlined,
  BranchesOutlined,
  BankOutlined,
  CloudOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';

export default {
  'Commit': (
    <SubnodeOutlined />
  ),
  'User': (
    <UserOutlined />
  ),
  'Branch': (
    <BranchesOutlined />
  ),
  'Organization': (
    <BankOutlined />
  ),
  'Repository': (
    <CloudOutlined />
  )
}