import React from 'react';

import Github from "./views/Github";
import Owners from "./views/Owners";

import Repository from "./views/Repository";
import Branch from "./views/Branch";
import WithGithub from "./views/wrappers/WithGithub";
import { Redirect } from "react-router";

const Redirector = () => {
  return (
    <Redirect to={'/github'} />
  );
}

export default [
  {
    path: '/',
    exact: true,
    component: Redirector,
  },
  {
    path: '/github',
    exact: true,
    component: WithGithub(Github),
  },
  {
    path: '/github/:owner/:repo',
    exact: true,
    component: WithGithub(Repository),
  },
  {
    path: '/github/:owner/:repo/:branch/:sha?',
    exact: true,
    component: WithGithub(Branch),
  }

]