import React, { useEffect, useState, Fragment } from 'react';
import { useStateContext } from '../contexts/StateContext';
import { Tree } from 'antd';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { RoutedHeader, PlainNavLink, LinkedTree } from '../hanu-ui/hanu-ui';
import icons from '../icons';

const Repositories = () => {
  const { state } = useStateContext();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    if (!state.oktokit) return;

    (async () => {
      const { data } = await state.oktokit.request("GET /user/repos?per_page=100");
      const owners = sortedUniqBy(data.map((row) => row.owner), (row) => {
        return row.login;
      }).map((item) => ({
        item,
        key: item.login,
        icon: icons[item.type],
        title: item.login,
      }));

      owners.forEach((owner) => {
        owner.children = data
          .filter((repo) => repo.owner.login === owner.item.login)
          .map((item) => ({
            item,
            key: item.full_name,
            icon: icons['Repository'],
            title: item.name,
            to: `/github/${item.full_name}`,
          }));
      })

      setTreeData(owners);
    })();

  }, [state.oktokit]);

  return (
    <div>
      <RoutedHeader
        title={'Repositories'}
      />
      <LinkedTree
        treeData={treeData}
      />
    </div>
  );
}

export default Repositories;